<template>
  <div class="footer">
    <div class="footernav">
      <!-- <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="rgba(0, 0, 0, 0)" router text-color='#fff'>
        <el-menu-item :index="item.forwardPath" v-for='item in indexList' :key='item.forwardPath'>{{item.menuName}}</el-menu-item>
      </el-menu> -->
         <ul>
        <li @click="jopindex(1)">首页</li>
        <li @click="jopindex(2)">核心产品</li>
        <li @click="jopindex(3)">行业方案</li>
        <li @click="jopindex(4)">新闻中心</li>
        <li @click="jopindex(5)">元宇宙</li>
        <li @click="jopindex(6)">关于我们</li>
      </ul>
      <div class="navright">
        <span>微信公众号</span>
        <img :src="footerList.logo" alt="">
      </div>
    </div>
    <div class="footeremali">
      <ul class='emalicon'>
        <li>{{footerList.serviceHotLine}}</li>
        <li>{{footerList.copyright}}</li>
        <li>{{footerList.recordNo}}</li>
        <li><img src="../assets/img/jbatb.png" alt="" class="imgstyle">&nbsp;京公网安备11011502038232号</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footerList: {
      type: Object,
      default: ""
    },
    indexList: {
      type: Array,
      default: ""
    }
  },
  methods: {
    handleSelect(key) {
    //   this.isShow = this.defaultActive != key;
    },
    jopindex(type){
      switch(type){
        case 1 :this.$router.push('/');
        return;
         case 2 :this.$router.push('/DigitalTwins');
        return;
         case 3 :this.$router.push('/EducationXR');
        return;
         case 4 :this.$router.push('/case');
        return;
         case 5 :this.$router.push('/goin');
        return;
         case 6 :this.$router.push('/download');
        return;
      }
    },
  }
}
</script>
<style lang="scss">
.footer {
  width: 100%;
  height: 180px;
  background-color: #272c8d;
  cursor:pointer;
  position: relative;
  left: 0;
  bottom: 0;
  .footernav {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #272c8d;
    font-size: 18px;
    color: #fff;
    ul{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width:600px;
      height: 50px;
    }
    // .el-menu{
    //   font-size: 18px!important;
    // }
    // .el-menu--horizontal {
    //   border-bottom: none !important;
    //   text-decoration: none !important;
    //   font-size: 18px!important;
    // }

    // .el-menu-item:hover {
    //   background: rgba(0, 0, 0, 0) !important;
    //   color: #fff !important;
    // }
    // .el-submenu__title:hover {
    //   background: #1c88cf !important;
    //   color: #fff !important;
    // }
    // .el-menu-item.is-active {
    //   background: #1c88cf !important;
    //   color: #fff !important;
  
    // }
    // .el-submenu__title.is-active {
    //   background: #1c88cf !important;
    //   color: #fff !important;

    // }
  }
  .navright {
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      color: #fff;
      margin-right: 40px;
    }
    img {
      width: 73px;
      height: 73px;
      margin-right: 40px;
    }
  }
  .footeremali {
    width: 100%;
    height: 80px;
    background-color: #010010;
    display: flex;
    justify-content: center;
    align-items: center;
    .emalicon {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        height: 24px;
        margin-right: 80px;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        align-items: center;
        .imgstyle{
          width:16px;
          height: 16px;
          display: inline-block;
        }
      }
     
    }
  }
  .el-submenu__title {
    height: 80px !important;
    line-height: 80px !important;
    // background: url("./assets/img/Mask1@2x.png")no-repeat;
  }
}
</style>
import request from '@/utils/request.js'

// 轮播
export function RotationChart() {
  return request({
    url: '/rotation/queryRotationList',
    method: 'get'
  })
}
// 模块
export function Firstlevelmodular() {
  return request({
    url: '/module/queryModuleAndShowList',
    method: 'get',
    // params
  })
}
// 菜单
export function Firstlevelmenu() {
  return request({
    url: '/menu/queryMenuAndNodeList',
    method: 'post'
  })
}
// 新闻
export function queryNewsList(params) {
  return request({
    url: '/news/reception/queryForPage',
    method: 'post',
    params
  })
}
// 上传
export function up(params) {
  return request({
    url: 'resume/resumeUpload',
    method: 'post',
    data:params
  })
}
export function upData(params) {
  return request({
    url: '/resume/create',
    method: 'post',
    data:params
  })
}
// 页脚
// 
export function queryPageSetupList() {
  return request({
    url: '/page/queryPageSetupList',
    method: 'post'
  })
}
<template>
  <div id="app">
    <div class='el-header' id='el-header'>
      <div class="logo">
        <img src="./assets/indexImg/logo.png" alt />
      </div>
      <div class='navrouter' id='navrouter'>
        <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          text-color="#fff" active-text-color="#fff" router popper-class='one' background-color="rgba(0,0,0,0.5)"
          id='el-menu-demo'>
          <template v-for="item in indexList">
            <el-submenu v-if="item.children.length > 0" :index="item.forwardPath" :key="item.forwardPath">
              <template slot="title">{{ item.menuName }}</template>
              <template v-for="item_1 in item.children">
                <el-menu-item :index="item_1.forwardPath" :key="item_1.forwardPath">{{ item_1.menuName }}</el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :index="item.forwardPath" :key="item.forwardPath">{{ item.menuName }}</el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class='conhaver'>
        <div id='QRcode'>
          <img src="../src/assets/indexImg/erwei.png" alt="">
          <div class='conhavershow'>
            <img src="./assets/indexImg/erweima2.png" alt="" style='width:70px;height:70px;margin-left:15px'>
            <span>微信公众号</span>
          </div>
        </div>
        <div id='QRcode'>
          <img src="../src/assets/indexImg/zx.png" alt="">
          <div class='conhavershow'>
            <span>全国服务热线</span>
            <span>010-62919812</span>
            <span>周一至周五</span>
            <span>9：00-18：00</span>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <!-- <footertext :footerList='footerList' :indexList='indexList'></footertext> -->
  </div>
</template>

<script>
import footertext from './components/footertext'
import $ from "jquery";
import { Firstlevelmenu, queryPageSetupList } from './api/index'
import contactUsVue from './views/AboutUs/contactUs.vue';

export default {
  components: {
    Firstlevelmenu,
    queryPageSetupList,
    // footertext
  },
  data() {

    return {
      widthwindow: "",
      router: true,
      defaultActive: "/",
      isShow: false,
      background: false,
      indexList: [],
      productlist: [],// 产品
      programmelist: [], // 方案
      Metaverselist: [], // y元宇宙
      welist: [], //关于我们
      corelist: [],//新闻中心
      mylist: [],//首页
      wholelist: [], // 全部数据

    };
  },
  created() {
    this.getmenu()
    this.getqueryPageSetupList()
    let nn = JSON.parse(localStorage.getItem('wholes'))
    this.indexList = nn
    window.onmousewheel = this.wheel
    this.clientWidthwatch()
    window.addEventListener('resize', this.clientWidthwatch); // 监听窗口大小变化

  },
  methods: {
    clientWidthwatch() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
          location.href = "https://gs.1001xr.com/html/yzsd-website-mobileend-web/#/"

        // 移动端  
      } else {
        // PC端  
        this.widthwindow = window.innerWidth
        if (window.innerWidth <= 1200) {
          console.log('pc端小于1200')
          location.href = "https://gs.1001xr.com/html/yzsd-website-mobileend-web/#/"
        }
      }
    },
    wheel(e) {
      var box_pon = document.getElementById("el-header");
      if (e.wheelDelta) {
        /*wheelDelta 判断鼠标滚动滚动条的反向（正数向上，负数向下）*/
        /*还有一个detail 也是判断鼠标滚动滚动条的反向的，不过正数表向下 负数表向上*/
        if (e.wheelDelta > 0) {/*判断浏览器IE，谷歌滑轮事件*/
          // if (document.documentElement.scrollTop > 0/*当滚动条大于0*/) {
          $("#el-header").css({ 'background': 'rgba(0,0,0,0.7)' });/*当滚动条的值大于0时，某标签内所有隐*/
          // $("#el-menu-demo").css({ 'display': 'block' ,'transition-duration': '1s'});
          $("#el-menu-demo").fadeIn("slow")
          // }
        } else {
          $("#el-header").css({ 'background': 'rgba(0,0,0,0)' });/*当滚动条的值等于0时，或着鼠标滚轮向上滚动*/
          // $("#el-menu-demo").css({ 'display': 'none','transition-duration': '1s' })
          $("#el-menu-demo").fadeOut("slow")
        }
      }

    },
    imgchange() {

    },
    handleSelect(key, keyPath) {
    },
    // 导航数据
    getmenu() {
      Firstlevelmenu().then(res => {
        this.indexList = res.data.responseData
        this.wholelist = res.data.responseData
        localStorage.setItem('wholes', JSON.stringify(this.wholelist)) //全部数据
        this.productlist = res.data.responseData[1].children,
          localStorage.setItem('product', JSON.stringify(this.productlist))// 平台产品
        this.programmelist = res.data.responseData[2].children,
          localStorage.setItem('programme', JSON.stringify(this.programmelist))// 行业方案
        // this.Metaverselist = res.data.responseData[2].children,
        // localStorage.setItem('Metaverse',JSON.stringify(this.Metaverselist))// 元宇宙
        this.welist = res.data.responseData[5].nodeList,
          localStorage.setItem('wh', JSON.stringify(this.welist))//关于我们
        // this.corelist = res.data.responseData[4].children,
        // localStorage.setItem('core',JSON.stringify(this.corelist))//新闻中心
        // this.mylist = res.data.responseData[5].children
        // localStorage.setItem('my',JSON.stringify(this.mylist))//首页

      })
    },

    // 页脚数据
    getqueryPageSetupList() {
      queryPageSetupList().then(res => {
        this.footerList = res.data.responseData[0]
        localStorage.setItem('footerlist', JSON.stringify(this.footerList))
      })
    }
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.el-header {
  height: 90px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99 !important;

  // .el-submenu__title {
  //   height: 80px !important;
  //   line-height: 80px !important;
  //   // background-color: rgba(0, 0, 0, 0);
  //   // 带有子菜单的
  // }
  // .el-menu--horizontal {
  //   border-bottom: none !important;
  //   text-decoration: none;
  //   background: rgba(0, 0, 0, 0);
  //   // 所有二级菜单
  // }
  // .el-menu--horizontal > .el-menu-item {
  //   height: 80px !important;
  //   line-height: 80px !important;
  //   // background: rgba(0, 0, 0, 0.5)
  // }
  .el-menu--horizontal {
    border-bottom: none !important;
    text-decoration: none;
    background: rgba(0, 0, 0, 0) !important;
  }

  .el-menu-item {
    background: rgba(0, 0, 0, 0) !important;
    height: 80px !important;
    line-height: 80px !important;
    border-bottom: none !important;
  }

  .el-menu-item:hover {
    // 滑过二级元素
    // background-color: #ccc;
    background: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
    border-bottom: none !important;
  }

  .el-submenu__title {
    height: 80px !important;
    line-height: 80px !important;
    // 二级路由页面
    background: rgba(0, 0, 0, 0) !important;
    border-bottom: none !important;
  }

  .el-submenu__title:hover {
    // 滑过的底层一级
    // background-color: #ccc;
    background: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
    border-bottom: none !important;
  }

  .el-menu-item.is-active {
    // 滑过的没一层
    // background-color: #fff !important;
    background: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
    border-bottom: none !important;
  }

  .el-submenu__title.is-active {
    background: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
    border-bottom: none !important;

    .one {
      background-color: #fff;
      border-bottom: none !important;
    }
  }

  .logo {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .navrouter {
    width: 580px !important;
  }

  .conhaver {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: bold;

    #QRcode {
      position: relative;
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }

      .conhavershow {
        display: none !important;
        bottom: -150px;
        right: 0px;
        position: absolute;
        width: 120px;
        height: 120px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px 10px;

        span {
          display: block;
          text-align: center;
        }
      }
    }

    #QRcode:hover {
      .conhavershow {
        display: block !important;
      }
    }
  }
}

.el-main {
  padding: 0 !important;
  // height: calc(100vh - 80px);
}
</style>
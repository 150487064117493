import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let yzsdrouter = new Router({
  // mode: 'history',
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      // redirect:'/',
    },
    {
      // 平台产品
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
      // redirect:'/DigitalTwins',
      children: [{
          path: '/DigitalTwins',
          name: 'DigitalTwins',
          title: '数字孪生智慧平台',
          component: () => import('./views/Product/DigitalTwins.vue')
        },
        {
          path: '/Education',
          name: 'Education',
          title: '智慧教育平台',
          component: () => import('./views/Product/Education.vue')
        },
        {
          path: '/PracticalTraining',
          name: 'PracticalTraining',
          title: '职业技能实训平台',
          component: () => import('./views/Product/PracticalTraining.vue')
        },
        {
          path: '/FinancialMedia',
          name: 'FinancialMedia',
          title: '5G融媒体平台',
          component: () => import('./views/Product/FinancialMedia.vue')
        },
        {
          path: '/Creationplatform',
          name: 'Creationplatform',
          title: 'VR创作平台',
          component: () => import('./views/Product/Creationplatform.vue')
        },
      ]
    },
    {
      // 行业方案
      path: '/product',
      name: 'product',
      component: () => import('./views/Product.vue'),
      // redirect:'/EducationXR',
      children: [{
          path: '/CulturalTourism',
          name: 'CulturalTourism',
          title: 'XR+文旅',
          component: () => import('./views/IndustryScheme/CulturalTourism.vue')
        },
        {
          path: '/EducationXR',
          name: 'EducationXR',
          title: 'XR+教育',
          component: () => import('./views/IndustryScheme/EducationXR.vue')
        },
        {
          path: '/IndustrialEnergy',
          name: 'IndustrialEnergy',
          title: 'XR+工业能源',
          component: () => import('./views/IndustryScheme/IndustrialEnergy.vue')
        },
        {
          path: '/MilitaryProject',
          name: 'MilitaryProject',
          title: 'XR+军工',
          component: () => import('./views/IndustryScheme/MilitaryProject.vue')
        },
        {
          path: '/PartyBuilding',
          name: 'PartyBuilding',
          title: 'XR+党建',
          component: () => import('./views/IndustryScheme/PartyBuilding.vue')
        },
        {
          path: '/Tobacco',
          name: 'Tobacco',
          title: 'XR+烟草',
          component: () => import('./views/IndustryScheme/Tobacco.vue')
        },
        {
          path: '/Traffic',
          name: 'Traffic',
          title: 'XR+交通',
          component: () => import('./views/IndustryScheme/Traffic.vue')
        },
      ]
    },
    {
      // 新闻中心
      path: '/case',
      name: 'case',
      component: () => import('./views/Case.vue')
    },
    {
      // 元宇宙
      path: '/goin',
      name: 'goin',
      component: () => import('./views/GoIn.vue')
    },
    {
      // 关于我们
      path: '/download',
      name: 'download',
      component: () => import('./views/Download.vue'),
      redirect: '/EnterpriseIntroduction',
      children: [{
          path: '/BrandCommitment',
          name: 'BrandCommitment',
          title: '品牌承诺',
          component: () => import('./views/AboutUs/BrandCommitment.vue')
        },
        {
          path: '/contactUs',
          name: 'contactUs',
          title: '联系我们',
          component: () => import('./views/AboutUs/BrandCommitment.vue')
        },
        {
          path: '/DevelopmentHistory',
          name: 'DevelopmentHistory',
          title: '发展历程',
          component: () => import('./views/AboutUs/DevelopmentHistory.vue')
        },
        {
          path: '/EnterpriseIntroduction',
          name: 'EnterpriseIntroduction',
          title: '企业介绍',
          component: () => import('./views/AboutUs/EnterpriseIntroduction.vue')
        },
        {
          path: '/EnterpriseQualification',
          name: 'EnterpriseQualification',
          title: '企业资质',
          component: () => import('./views/AboutUs/BrandCommitment.vue')
        },
        {
          path: '/TalentRecruitment',
          name: 'TalentRecruitment',
          title: '人才招聘',
          component: () => import('./views/AboutUs/TalentRecruitment.vue')
        },
      ]
    },
    {
      //新闻内容
      path: '/NewsContent',
      name: 'NewsContent',
      component: () => import('./views/NewsContent.vue'),
    },
    {
      //新闻内容
      path: '/RecruitmentPage',
      name: 'RecruitmentPage',
      component: () => import('./views/RecruitmentPage.vue'),
    },
    {
      //元宇宙内容
      path: '/Golncontent',
      name: 'Golncontent',
      component: () => import('./views/Golncontent.vue'),
    },
    {
      path: "*",
      redirect: {
        path: "/404",
      },
    },
  ],

})

// // 判断是否需要登录权限 以及是否登录
// yzsdrouter.beforeEach((to, from, next) => {
//   // 判断是否需要登录权限
//   if (to.matched.some(res => res.meta.requireAuth)) {
//     // 判断是否登录
//     if (sessionStorage.getItem('token')) {
//       next()
//     } else {
//       // 没登录则跳转到登录界面
//       next({
//         path: '/login',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     }
//   } else {
//     next()
//   }
// })

export default yzsdrouter
import { render, staticRenderFns } from "./contactUs.vue?vue&type=template&id=5d6d12f3"
import script from "./contactUs.vue?vue&type=script&lang=js"
export * from "./contactUs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);
import './assets/css/contentstyle.css'
import $ from 'jquery' //加上这句话
Vue.config.productionTip = false
Vue.prototype.$ = $; // 当然还有这句话 给vue原型上添加 $

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
// 百度地图
import BaiduMap from 'vue-baidu-map'
// 百度地图
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥
  ak: 'C1ljWw7O6o3QtocmCZCfXnwlmAFNXpWC'
})
router.afterEach((to,from,next) => {
  // window.scrollTo(0,0);
  // alert('111111')
  
  // top.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);
  // next();
  // console.log( window.scrollTo)
})

// import $ from "jquery";

// import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// Vue.use(VideoPlayer)

// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload, {
//   //完全显示的时候加载
//   preLoad: 1,
//   //失败时显示的图片
//   error: require('./assets/img/error.png'),
//   //加载时显示的GIF图
//   loading: require('./assets/img/loading.gif'),
//   //尝试加载几次
//   attempt: 1
// });

//设置超时时间
axios.defaults.timeout = 3000;
//设置api地址
//全局定义axios
axios.defaults.baseURL = '/yzsd-website/';
Vue.prototype.$http = axios

// 图片服务器地址
// Vue.prototype.imgserver = 'http://shkjgw.shkjem.com/'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')